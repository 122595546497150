import { PAGES } from "../constants/pathConstants";
import { CompanyUser, Order, Batch, ProductionStage, Project, ProductionStagesTimeManagement, TaskMention, TaskMentionType } from "../interfaces";
import { EncodedMention, MentionItem } from "../interfaces/editor";

/**
 * Extracts mentions from HTML content
 * @param html HTML content with mentions
 * @returns Array of encoded mentions
 */
export function extractMentionsFromHtml(html: string): EncodedMention[] {
    const mentions: EncodedMention[] = [];
    const mentionRegex = /<span data-type="mention" data-id="([^"]+)" data-mentioned-id="([^"]+)" data-label="([^"]+)" data-mention-type="([^"]+)"[^>]*>@([^<]+)<\/span>/g;

    // Fallback for backward compatibility with old format
    const oldMentionRegex = /<span data-type="mention" data-id="([^"]+)" data-label="([^"]+)" data-mention-type="([^"]+)"[^>]*>@([^<]+)<\/span>/g;

    // Try the new format first
    let match;
    while ((match = mentionRegex.exec(html)) !== null) {
        mentions.push({
            id: match[1],         // This is the TaskMention id
            mentionedId: match[2], // This is the original entity id
            label: match[3],
            type: match[4] as TaskMentionType,
        });
    }

    // If no mentions found with new format, try the old format
    if (mentions.length === 0) {
        while ((match = oldMentionRegex.exec(html)) !== null) {
            mentions.push({
                id: match[1],
                mentionedId: match[1], // In old format, id is the same as mentionedId
                label: match[2],
                type: match[3] as TaskMentionType,
            });
        }
    }

    return mentions;
}

/**
 * Converts HTML mentions to TaskMention objects
 * @param html HTML content with mentions
 * @returns Array of TaskMention objects
 */
export function extractTaskMentionsFromHtml(html: string): TaskMention[] {
    const encodedMentions = extractMentionsFromHtml(html);

    return encodedMentions.map(mention => ({
        id: mention.id,
        mentionedId: mention.mentionedId || mention.id,
        label: mention.label,
        type: mention.type,
    }));
}

/**
 * Converts mention items to a format suitable for the editor
 * @param items Array of items to convert to mention format
 * @param type Type of mention
 * @returns Array of mention items
 */
export function convertToMentionItems<T extends { id: string; name?: string; title?: string; projectId?: string }>(
    items: T[],
    type: TaskMentionType,
    projects: Project[] = []
): MentionItem[] {
    return items.map((item) => ({
        id: item.id,
        label: item.name || item.title || `${type}-${item.id}`,
        type,
        projectName: projects.find((project) => project.id === item.projectId)?.name,
        projectId: item.projectId,
    }));
}

export function getMentionPath(mention: TaskMention): {
    isPossible: boolean;
    path?: string;
    errorMessage?: string;
} {
    switch (mention.type) {
        case TaskMentionType.PROJECT:
            return {
                isPossible: true,
                path: `/projects/${mention.mentionedId}`,
            };
        case TaskMentionType.BATCH:
            if (mention.projectId) {
                return {
                    isPossible: true,
                    path: PAGES.BATCH(mention.projectId, mention.mentionedId),
                };
            } else {
                return {
                    isPossible: false,
                    errorMessage: "Batch mentions must be associated with a project",
                };
            }
        case TaskMentionType.PRODUCTION_STAGE:
            if (mention.projectId) {
                return {
                    isPossible: true,
                    path: PAGES.PRODUCTION_STAGE(mention.projectId, mention.mentionedId),
                };
            } else {
                return {
                    isPossible: false,
                    errorMessage: "Production stage mentions must be associated with a project",
                };
            }
        case TaskMentionType.ORDER:
            if (mention.projectId) {
                return {
                    isPossible: true,
                    path: PAGES.ORDER(mention.projectId, mention.mentionedId),
                };
            } else {
                return {
                    isPossible: false,
                    errorMessage: "Order mentions must be associated with a project",
                };
            }
        case TaskMentionType.USER:
            return {
                isPossible: false,
                errorMessage: "User mentions cannot be forwarded",
            };
        default:
            return {
                isPossible: false,
                errorMessage: "Unknown mention type",
            };
    }
}

/**
 * Prepares mention items from various data sources
 * @param projects Projects array
 * @param batches Batches array
 * @param productionStages Production stages array
 * @param orders Orders array
 * @param users Users array
 * @returns Combined array of mention items
 */
export function prepareMentionItems(
    projects: Project[] = [],
    batches: Batch[] = [],
    productionStages: ProductionStage[] = [],
    orders: Order[] = [],
    users: CompanyUser[] = [],
): MentionItem[] {
    return [
        ...convertToMentionItems(projects, TaskMentionType.PROJECT),
        ...convertToMentionItems(batches, TaskMentionType.BATCH, projects),
        ...convertToMentionItems(productionStages, TaskMentionType.PRODUCTION_STAGE, projects),
        ...convertToMentionItems(orders, TaskMentionType.ORDER, projects),
        ...users.map((user) => ({
            id: user.id,
            label: user.email,
            type: TaskMentionType.USER,
        })),
    ];
} 