/**
 * Application path constants for navigation
 * Used for consistent routing throughout the application
 */

export const PAGES = {
    // Dashboard
    DASHBOARD: '/',

    // Projects
    PROJECTS: '/projects',
    PROJECT_DETAILS: (projectId: string) => `/projects/${projectId}`,

    // Batches
    BATCHES: (projectId: string) => `/projects/${projectId}/batches`,
    BATCH: (projectId: string, batchId: string) => `/projects/${projectId}/batches?batch=${batchId}`,
    BATCH_DETAILS: (projectId: string, batchId: string) => `/projects/${projectId}/batches?batch=${batchId}`,

    // Production Stages
    PRODUCTION_STAGES: (projectId: string) => `/projects/${projectId}/production-stages`,
    PRODUCTION_STAGE: (projectId: string, stageId: string) => `/projects/${projectId}/production-stages?stage=${stageId}`,

    // Orders
    ORDERS: (projectId: string) => `/projects/${projectId}/orders`,
    ORDER: (projectId: string, orderId: string) => `/projects/${projectId}/orders?order=${orderId}`,
    ORDER_DETAILS: (projectId: string, orderId: string) => `/projects/${projectId}/orders?order=${orderId}`,

    // Production Stage Timelines
    PROD_STAGE_TIMELINES: (projectId: string) => `/projects/${projectId}/prod-stage-timelines`,
    // PROD_STAGE_TIMELINE_DETAILS: (timelineId: string) => `/prod-stage-timelines/${timelineId}`,

    // Tasks
    TASKS: '/tasks',
    TASK: (taskId: string) => `/tasks?task=${taskId}`,

    // Settings
    SETTINGS: '/settings',

    // Profile
    PROFILE: '/profile',
}; 