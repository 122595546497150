import React, { useState, useRef, useEffect } from "react";
import { Input } from "@/app/components/ui/input";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { cn } from "@/app/lib/utils";
import { Project, Order, Batch, TaskRelation } from "@/app/lib/interfaces";

interface SelectorItem {
  id: string; // relatedTo.id (projectId, orderId, batchId)
  label: string;
  type: "project" | "order" | "batch";
  projectId?: string;
  projectName?: string;
  orderId?: string;
}

interface EnhancedSelectorProps {
  projects: Project[];
  orders: Order[];
  batches: Batch[];
  value?: string;
  onChange: (value: TaskRelation | undefined) => void;
  className?: string;
}

export function EnhancedSelector({
  projects,
  orders,
  batches,
  value,
  onChange,
  className,
}: EnhancedSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLButtonElement>(null);

  // Prepare items for the selector
  const items: SelectorItem[] = [
    ...projects.map((project) => ({
      id: project.id,
      label: project.name,
      type: "project" as const,
    })),
    ...orders.map((order) => ({
      id: order.id,
      label: order.name,
      type: "order" as const,
      projectId: order.projectId,
      projectName: projects.find((p) => p.id === order.projectId)?.name,
    })),
    ...batches.map((batch) => ({
      id: batch.id,
      label: batch.name,
      type: "batch" as const,
      projectId: batch.projectId,
      orderId: batch.orderId,
      projectName: projects.find((p) => p.id === batch.projectId)?.name,
    })),
  ];

  // Get the selected item
  const selectedItem = items.find((item) => item.id === value);

  // Filter items based on search query
  const filteredItems = items.filter((item) => {
    if (!searchQuery) return true;
    const normalizedQuery = searchQuery.toLowerCase().replace(/\s/g, "");
    return (
      item.label.toLowerCase().includes(normalizedQuery) ||
      item.type.toLowerCase().includes(normalizedQuery) ||
      (item.projectName?.toLowerCase().includes(normalizedQuery) ?? false)
    );
  });

  // Update selected index when opening dropdown or when value changes
  useEffect(() => {
    if (isOpen && value) {
      const index = filteredItems.findIndex((item) => item.id === value);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
  }, [isOpen, value, filteredItems]);

  // Handle selection
  const handleSelect = (item: SelectorItem) => {
    onChange({
      id: item.id,
      type: item.type,
      projectId: item.projectId,
      orderId: item.orderId,
    });
    setIsOpen(false);
    setSearchQuery("");
    setSelectedIndex(0);
  };

  // Handle keyboard navigation
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setSelectedIndex((prev) => (prev + 1) % filteredItems.length);
        break;
      case "ArrowUp":
        event.preventDefault();
        setSelectedIndex(
          (prev) => (prev - 1 + filteredItems.length) % filteredItems.length
        );
        break;
      case "Enter":
        event.preventDefault();
        if (filteredItems[selectedIndex]) {
          handleSelect(filteredItems[selectedIndex]);
        }
        break;
      case "Escape":
        event.preventDefault();
        setIsOpen(false);
        setSearchQuery("");
        setSelectedIndex(0);
        break;
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setSearchQuery("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ block: "nearest" });
    }
  }, [selectedIndex]);

  // Get color for type badge
  const getColorForType = (type: string) => {
    switch (type) {
      case "project":
        return "bg-blue-100 text-blue-800";
      case "batch":
        return "bg-green-100 text-green-800";
      case "order":
        return "bg-orange-100 text-orange-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="relative">
        <div className="relative">
          <Input
            value={isOpen ? searchQuery : selectedItem?.label || ""}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setIsOpen(true);
            }}
            onFocus={() => {
              setIsOpen(true);
              setSearchQuery("");
            }}
            onBlur={() => {
              // Small delay to allow click events to fire
              setTimeout(() => {
                if (!isOpen) {
                  setSearchQuery("");
                }
              }, 200);
            }}
            onKeyDown={handleKeyDown}
            placeholder="Search projects, orders, or batches..."
            className={cn("w-full", className)}
          />
          {!isOpen && selectedItem && (
            <div className="absolute right-10 top-1/2 -translate-y-1/2 flex items-center gap-2">
              {selectedItem.projectName && (
                <span className="text-xs text-gray-500 italic">
                  {selectedItem.projectName}
                </span>
              )}
              <span
                className={cn(
                  "text-xs px-2 py-1 rounded-full",
                  getColorForType(selectedItem.type)
                )}
              >
                {selectedItem.type}
              </span>
            </div>
          )}
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => {
              setIsOpen(!isOpen);
              if (!isOpen) {
                setSearchQuery("");
              }
            }}
          >
            <ChevronUpDownIcon className="h-4 w-4 text-gray-400" />
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
          {filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              <button
                key={`${item.type}-${item.id}`}
                ref={index === selectedIndex ? selectedRef : null}
                className={cn(
                  "w-full px-3 py-2 text-left rounded-md",
                  index === selectedIndex
                    ? "bg-blue-50 text-blue-900"
                    : "text-gray-800 hover:bg-gray-50"
                )}
                onClick={() => handleSelect(item)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <span className="font-medium truncate mr-2">
                      {item.label}
                    </span>
                    {item.projectName && (
                      <span className="ml-3 text-xs text-gray-500 italic">
                        {item.projectName}
                      </span>
                    )}
                  </div>
                  <span
                    className={cn(
                      "text-xs px-2 py-1 rounded-full",
                      getColorForType(item.type)
                    )}
                  >
                    {item.type}
                  </span>
                </div>
              </button>
            ))
          ) : (
            <div className="py-2 px-3 text-gray-500">No items found</div>
          )}
        </div>
      )}
    </div>
  );
}
