import { Store } from "@reduxjs/toolkit";
import { AppDispatch, RootState, store } from "../store/store";
import { Task, TaskAttachment } from "../interfaces";
import { addTask, softDeleteTask, updateTask } from "../store/slices/tasks/tasksSlice";
import toast from "react-hot-toast";
import { uploadTaskAttachment } from "../firebase/storage";

export interface TaskAttachmentWithFile extends TaskAttachment {
    file: File;
    localUrl?: string;
}

export interface TaskToUpload extends Omit<Task, "id"> {
    id?: string;
    attachments?: TaskAttachmentWithFile[];
}

export class TasksService {
    private static instances: Record<string, TasksService> = {};

    private constructor(
        private store: Store<RootState>,
        private companyId: string,
    ) {
        this.dispatch = this.store.dispatch;
    }

    private dispatch: AppDispatch;

    static getInstance(companyId: string): TasksService {
        if (!this.instances[companyId]) {
            this.instances[companyId] = new TasksService(store, companyId);
        }
        return this.instances[companyId];
    }

    async addTask(task: TaskToUpload) {
        let attachments: TaskAttachment[] | undefined = undefined;
        let processedDescription = task.description || "";

        if (task.attachments && task.attachments.length > 0) {
            attachments = await this.uploadAttachments(task.attachments);

            // Replace local URLs with Firebase URLs in the description
            if (task.description) {
                processedDescription = this.replaceImageUrls(
                    task.description,
                    task.attachments,
                    attachments
                );
            }
        }

        const taskToUpload: Omit<Task, "id"> = {
            ...task,
            description: processedDescription,
            attachments: attachments,
        }

        toast.promise(
            this.dispatch(addTask({ task: taskToUpload })).unwrap(),
            {
                loading: "Saving task...",
                success: "Task saved",
                error: (err) => {
                    // Log the full error object to console
                    console.error("Failed to save task:", err);

                    // Return a user-friendly message for the toast
                    return "Failed to save task";
                },
            }
        )
    }

    async uploadAttachments(attachments: TaskAttachmentWithFile[]) {
        const uploadedAttachments: TaskAttachment[] = []
        await Promise.all(attachments.map(async (attachment) => {
            const file = await uploadTaskAttachment(this.companyId, attachment.id, attachment.file);
            uploadedAttachments.push({
                id: attachment.id,
                url: file,
                type: attachment.type,
                name: attachment.name,
                isForDescription: attachment.isForDescription,
            });
            return file;
        }));
        return uploadedAttachments;
    }

    async updateTask(task: Partial<TaskToUpload>, taskId: string) {
        let attachments: TaskAttachment[] = task.attachments?.filter((attachment) => !attachment.file) ?? [];
        let processedDescription = task.description || "";

        if (task.attachments?.some((attachment) => attachment.file)) {
            const attachmentsToUpload = task.attachments?.filter((attachment) => attachment.file) ?? [];
            const uploadedAttachments = await this.uploadAttachments(attachmentsToUpload);
            attachments = [...attachments, ...uploadedAttachments];

            // Replace local URLs with Firebase URLs in the description
            if (task.description) {
                processedDescription = this.replaceImageUrls(
                    task.description,
                    attachmentsToUpload,
                    uploadedAttachments
                );
            }
        }

        const taskToUpdate: Partial<Task> = {
            ...task,
            description: processedDescription,
            attachments: attachments,
        }

        toast.promise(
            this.dispatch(updateTask({ task: taskToUpdate, taskId })).unwrap(),
            {
                loading: "Updating task...",
                success: "Task updated",
                error: "Failed to update task",
            }
        )
    }

    // Helper method to replace local image URLs with Firebase URLs in HTML content
    private replaceImageUrls(
        html: string,
        localAttachments: TaskAttachmentWithFile[],
        uploadedAttachments: TaskAttachment[]
    ): string {
        let processedHtml = html;

        // Create a mapping of local URLs to their Firebase URLs
        const urlMap = new Map<string, string>();
        for (let i = 0; i < localAttachments.length; i++) {
            const localAttachment = localAttachments[i];
            const uploadedAttachment = uploadedAttachments.find((attachment) => attachment.id === localAttachment.id);
            if (localAttachment?.localUrl && uploadedAttachment?.url) {
                urlMap.set(localAttachment.localUrl, uploadedAttachment.url);
            }
        }

        // Use DOM parsing to find and replace image URLs
        const parser = new DOMParser();
        const doc = parser.parseFromString(processedHtml, 'text/html');

        // Find all images
        const images = doc.querySelectorAll('img');
        images.forEach(img => {
            const src = img.getAttribute('src');
            if (src && urlMap.has(src)) {
                img.setAttribute('src', urlMap.get(src)!);
            }
        });

        // Convert back to HTML string
        return doc.body.innerHTML;
    }

    async deleteTask(taskId: string) {
        toast.promise(
            this.dispatch(softDeleteTask({ taskId })).unwrap(),
            {
                loading: "Deleting task...",
                success: "Task deleted",
                error: "Failed to delete task",
            }
        )
    }
}

export const tasksService = (companyId: string) => TasksService.getInstance(companyId);