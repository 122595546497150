"use client";

import React, { createContext, useContext, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/app/components/ui/dialog";
import TaskForm from "@/app/components/TaskForm";

interface TaskDialogContextType {
    isCreateDialogOpen: boolean;
    openCreateDialog: () => void;
    closeCreateDialog: () => void;
}

const TaskDialogContext = createContext<TaskDialogContextType | undefined>(undefined);

export function TaskDialogProvider({ children }: { children: React.ReactNode }) {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

    const openCreateDialog = () => {
        setIsCreateDialogOpen(true);
    };

    const closeCreateDialog = () => {
        setIsCreateDialogOpen(false);
    };

    const handleCreateSuccess = () => {
        closeCreateDialog();
    };

    return (
        <TaskDialogContext.Provider
            value={{
                isCreateDialogOpen,
                openCreateDialog,
                closeCreateDialog,
            }}
        >
            {children}

            {/* Global Create Task Dialog */}
            <Dialog
                open={isCreateDialogOpen}
                onClose={closeCreateDialog}
            >
                <DialogContent
                    className="sm:max-w-md mt-[56px] md:mt-[72px]"
                    onCloseButton={closeCreateDialog}
                >
                    <DialogHeader>
                        <DialogTitle>Create New Task</DialogTitle>
                    </DialogHeader>
                    <TaskForm
                        onSuccess={handleCreateSuccess}
                        onCancel={closeCreateDialog}
                    />
                </DialogContent>
            </Dialog>
        </TaskDialogContext.Provider>
    );
}

export function useTaskDialog() {
    const context = useContext(TaskDialogContext);
    if (context === undefined) {
        throw new Error("useTaskDialog must be used within a TaskDialogProvider");
    }
    return context;
} 