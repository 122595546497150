import React, { useState, useEffect, useRef } from "react";
import {
  CompanyUser,
  Task,
  TaskPriority,
  TaskStatus,
  TaskMention,
  TaskRelation,
} from "@/app/lib/interfaces";
import { Button } from "@/app/components/ui/button";
import { Input } from "@/app/components/ui/input";
import { Label } from "@/app/components/ui/label";
import { tasksService, TaskToUpload } from "@/app/lib/services/tasks.service";
import { selectSelectedCompany } from "@/app/lib/store/slices/appSelectors";
import toast from "react-hot-toast";
import {
  XMarkIcon,
  CheckIcon,
  ChevronUpDownIcon,
  PaperClipIcon,
  DocumentIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { cn } from "@/app/lib/utils";
import "react-datepicker/dist/react-datepicker.css";
import { useAppSelector } from "../lib/store/hooks";
import { useAuth } from "../context/auth-context";
import { selectCompanyUsersArray } from "../lib/store/slices/companyUsers/companyUsersSelectors";
import { DateService } from "../lib/services/date.service";
import { selectProjectsArray } from "../lib/store/slices/projects/projectsSelectors";
import { selectAllBatchesArray } from "../lib/store/slices/batches/batchesSelectors";
import { selectAllProductionStagesArray } from "../lib/store/slices/productionStages/productionStagesSelectors";
import { selectAllOrdersArray } from "../lib/store/slices/orders/ordersSelectors";
import dynamic from "next/dynamic";
import { JSONContent } from "@tiptap/react";
import { TaskAttachmentWithFile } from "@/app/lib/services/tasks.service";
import { v4 as uuidv4 } from "uuid";
import { FilePasteArea } from "../lib/ui/FilePasteArea";
import { prepareMentionItems } from "../lib/utils/mentionUtils";
import { Optional } from "../lib/types/global";
import { EnhancedSelector } from "@/app/components/ui/EnhancedSelector";

// Import RichTextEditor with SSR disabled
const RichTextEditor = dynamic(
  () => import("./editor/RichTextEditor").then((mod) => mod.RichTextEditor),
  { ssr: false }
);

interface TaskFormProps {
  initialTask?: Partial<Task>;
  onSuccess?: () => void;
  onCancel?: () => void;
  className?: string;
}

export default function TaskForm({
  initialTask,
  onSuccess,
  onCancel,
  className,
}: TaskFormProps) {
  const { user } = useAuth();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const companyUsers = useAppSelector(selectCompanyUsersArray);
  const projects = useAppSelector(selectProjectsArray);
  const batches = useAppSelector(selectAllBatchesArray);
  const productionStages = useAppSelector(selectAllProductionStagesArray);
  const orders = useAppSelector(selectAllOrdersArray);

  const [title, setTitle] = useState(initialTask?.title || "");
  const [description, setDescription] = useState<string>(
    initialTask?.description || ""
  );
  const [priority, setPriority] = useState<TaskPriority>(
    initialTask?.priority || "medium"
  );
  const [dueDate, setDueDate] = useState<string | undefined>(
    initialTask?.dueAt || undefined
  );
  const [relatedTo, setRelatedTo] = useState<TaskRelation | undefined>(
    initialTask?.relatedTo || undefined
  );
  const [assignedUsers, setAssignedUsers] = useState<string[]>(
    initialTask?.assignedTo || []
  );
  const [newAssignee, setNewAssignee] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<CompanyUser[]>([]);
  const [isAssigneeDropdownOpen, setIsAssigneeDropdownOpen] = useState(false);
  const assigneeDropdownRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Add state for tracking attachments from RichTextEditor
  const [taskAttachments, setTaskAttachments] = useState<
    TaskAttachmentWithFile[]
  >(
    (initialTask?.attachments
      ?.filter((att) => att.isForDescription)
      ?.map((att) => ({
        ...att,
        localUrl: att.url, // Use the existing URL for initial attachments
      })) as TaskAttachmentWithFile[]) || []
  );

  // Add state for tracking file attachments (separate from RichTextEditor)
  const [fileAttachments, setFileAttachments] = useState<
    TaskAttachmentWithFile[]
  >(
    (initialTask?.attachments
      ?.filter((att) => !att.isForDescription)
      ?.map((att) => ({
        ...att,
        localUrl: att.url, // Use the existing URL for initial attachments
      })) as TaskAttachmentWithFile[]) || []
  );

  // TODO: move the page forwarding on clicking mentions logic from RichTextEditor to here

  // File input ref
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Prepare mention items
  const mentionItems = prepareMentionItems(
    projects,
    batches,
    productionStages,
    orders,
    companyUsers
  );

  // Add state for tracking mentions
  const [taskMentions, setTaskMentions] = useState<TaskMention[]>(
    initialTask?.mentions || []
  );

  // Filter users whenever input changes
  useEffect(() => {
    if (newAssignee.trim() === "") {
      setFilteredUsers(companyUsers);
    } else {
      const filtered = companyUsers.filter(
        (user) =>
          user.email?.toLowerCase().includes(newAssignee.toLowerCase()) ||
          user.userId?.toLowerCase().includes(newAssignee.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [newAssignee, companyUsers]);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        assigneeDropdownRef.current &&
        !assigneeDropdownRef.current.contains(event.target as Node)
      ) {
        setIsAssigneeDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle image paste from the editor
  const handleImagePaste = (attachment: TaskAttachmentWithFile) => {
    setTaskAttachments((prev) => [...prev, attachment]);
  };

  // Handle image deletion from the editor
  const handleImageDelete = (attachmentId: string) => {
    console.log("handleImageDelete", attachmentId);
    setTaskAttachments((prev) => prev.filter((att) => att.id !== attachmentId));
  };

  // Handle file selection from input
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    // Check if adding these files would exceed the 10 file limit
    if (fileAttachments.length + files.length > 10) {
      toast.error("You can only attach up to 10 files");
      return;
    }

    const newAttachments: TaskAttachmentWithFile[] = [];

    Array.from(files).forEach((file) => {
      const attachment: TaskAttachmentWithFile = {
        id: uuidv4(),
        name: file.name,
        type: file.type,
        file: file,
        localUrl: URL.createObjectURL(file),
        isForDescription: false,
      };
      newAttachments.push(attachment);
    });

    setFileAttachments((prev) => [...prev, ...newAttachments]);

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Handle file paste from FilePasteArea
  const handleFilePaste = (file: File, previewUrl: string) => {
    // Check if adding this file would exceed the 10 file limit
    if (fileAttachments.length >= 10) {
      toast.error("You can only attach up to 10 files");
      return;
    }

    const attachment: TaskAttachmentWithFile = {
      id: uuidv4(),
      name: file.name,
      type: file.type,
      file: file,
      localUrl: previewUrl,
      isForDescription: false,
    };

    setFileAttachments((prev) => [...prev, attachment]);
  };

  // Handle file removal
  const handleFileRemove = (attachmentId: string) => {
    setFileAttachments((prev) => prev.filter((att) => att.id !== attachmentId));
  };

  // Handle editor content change
  const handleEditorChange = (html: string, json: JSONContent) => {
    setDescription(html);
  };

  // Handle mention add
  const handleMentionAdd = (mention: TaskMention) => {
    console.log("handleMentionAdd", mention);
    setTaskMentions((prev) => [...prev, mention]);
  };

  // Handle mention delete
  const handleMentionDelete = (mentionId: string) => {
    console.log("handleMentionDelete", mentionId);
    setTaskMentions((prev) =>
      prev.filter((mention) => mention.id !== mentionId)
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title.trim()) {
      toast.error("Task title is required");
      return;
    }

    if (!selectedCompany || !user) {
      toast.error("Company or user information is missing");
      return;
    }

    setIsSubmitting(true);

    try {
      const taskService = tasksService(selectedCompany.companyId);

      // Combine both types of attachments
      const allAttachments = [...taskAttachments, ...fileAttachments];

      const taskData: TaskToUpload = {
        id: initialTask?.id || undefined,
        title: title.trim(),
        description: description.trim(),
        priority,
        status: initialTask?.status || ("pending" as TaskStatus),
        createdBy: initialTask?.createdBy || user.uid,
        ownerId: initialTask?.ownerId || user.uid,
        assignedTo: assignedUsers.length > 0 ? assignedUsers : undefined,
        dueAt: dueDate ?? undefined,
        relatedTo: relatedTo ?? undefined,
        attachments: allAttachments,
        mentions: taskMentions.length > 0 ? taskMentions : undefined,
      };

      console.log("taskData", taskData);

      if (initialTask?.id) {
        await taskService.updateTask(taskData, initialTask.id);
      } else {
        await taskService.addTask(taskData);
      }

      if (onSuccess) {
        onSuccess();
      }

      // Reset form if it's a new task
      if (!initialTask?.id) {
        setTitle("");
        setDescription("");
        setPriority("medium");
        setDueDate(undefined);
        setRelatedTo(undefined);
        setAssignedUsers([]);
        setTaskAttachments([]);
        setFileAttachments([]);
        setTaskMentions([]);
      }
    } catch (error) {
      console.error("Error saving task:", error);
      toast.error("Failed to save task");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddAssignee = (userId: string) => {
    if (userId && !assignedUsers.includes(userId)) {
      setAssignedUsers([...assignedUsers, userId]);
      setNewAssignee("");

      // Keep dropdown open and focus the input after selecting
      const inputElement = document.getElementById(
        "assignees"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const handleRemoveAssignee = (assignee: string) => {
    setAssignedUsers(assignedUsers.filter((user) => user !== assignee));
  };

  return (
    <form onSubmit={handleSubmit} className={cn("space-y-4", className)}>
      <span className="text-sm text-muted-foreground text-slate-400">
        for {selectedCompany?.companyName}
      </span>
      <div>
        <Label htmlFor="title">Title *</Label>
        <Input
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Task title"
          required
        />
      </div>

      <div>
        <Label htmlFor="description">Description</Label>
        <RichTextEditor
          initialContent={description}
          placeholder="Task description..."
          onChange={handleEditorChange}
          mentionItems={mentionItems}
          onImagePaste={handleImagePaste}
          onImageDelete={handleImageDelete}
          onMentionAdd={handleMentionAdd}
          onMentionDelete={handleMentionDelete}
          initialMentions={taskMentions}
        />
      </div>

      <div>
        <Label htmlFor="relatedTo">Related To</Label>
        <EnhancedSelector
          projects={projects}
          orders={orders}
          batches={batches}
          value={relatedTo?.id}
          onChange={(value) => setRelatedTo(value)}
        />
      </div>

      {/* File Attachments Section */}
      <div>
        <Label className="mb-2 block">
          Attachments ({fileAttachments.length}/10)
        </Label>

        <div className="flex flex-col gap-4">
          {/* File Input */}
          <div className="flex items-center gap-2">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              multiple
              className="hidden"
              disabled={fileAttachments.length >= 10}
            />
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => fileInputRef.current?.click()}
              disabled={fileAttachments.length >= 10}
            >
              <PaperClipIcon className="h-4 w-4 mr-2" />
              Select Files
            </Button>
            <span className="text-sm text-gray-500">
              {fileAttachments.length >= 10
                ? "Maximum 10 files reached"
                : `${10 - fileAttachments.length} files remaining`}
            </span>
          </div>

          {/* File Paste Area */}
          {fileAttachments.length < 10 && (
            <FilePasteArea
              onFilePaste={handleFilePaste}
              className="mt-2"
              placeholderText="Click here and paste a file (Ctrl+V)"
            />
          )}

          {/* Attached Files List */}
          {fileAttachments.length > 0 && (
            <div className="mt-2 space-y-2">
              <Label>Attached Files</Label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {fileAttachments.map((attachment) => (
                  <div
                    key={attachment.id}
                    className="flex items-center justify-between p-2 border rounded-md bg-gray-50"
                  >
                    <div className="flex items-center gap-2 overflow-hidden">
                      <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
                      <span
                        className="text-sm truncate"
                        title={attachment.name}
                      >
                        {attachment.name}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleFileRemove(attachment.id)}
                      className="text-gray-500 hover:text-red-500"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <Label htmlFor="priority">Priority</Label>
        <div className="flex gap-2 mt-1">
          {(["low", "medium", "high"] as TaskPriority[]).map((p) => (
            <Button
              key={p}
              type="button"
              variant={priority === p ? "default" : "outline"}
              size="sm"
              onClick={() => setPriority(p)}
              className={cn(
                "capitalize",
                priority === p &&
                  p === "low" &&
                  "bg-blue-500 hover:bg-blue-600",
                priority === p &&
                  p === "medium" &&
                  "bg-yellow-500 hover:bg-yellow-600",
                priority === p && p === "high" && "bg-red-500 hover:bg-red-600"
              )}
            >
              {p}
            </Button>
          ))}
        </div>
      </div>

      <div>
        <Label htmlFor="dueDate">Due Date</Label>
        <div className="flex flex-wrap gap-2 mt-1 mb-2">
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              setDueDate(
                DateService.getLocalDateInMsFromNow(24 * 60 * 60 * 1000)
              )
            }
          >
            Tomorrow
          </Button>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              setDueDate(
                DateService.getLocalDateInMsFromNow(2 * 24 * 60 * 60 * 1000)
              )
            }
          >
            In 2 days
          </Button>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              setDueDate(
                DateService.getLocalDateInMsFromNow(7 * 24 * 60 * 60 * 1000)
              )
            }
          >
            In a week
          </Button>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              setDueDate(
                DateService.getLocalDateInMsFromNow(30 * 24 * 60 * 60 * 1000)
              )
            }
          >
            In a month
          </Button>
        </div>
        <div className="mt-1">
          <input
            type="date"
            value={dueDate ? dueDate.split("T")[0] : ""}
            onChange={(e) =>
              setDueDate(
                e.target.value
                  ? DateService.getLocalDateFromDateInputTarget(e.target.value)
                  : undefined
              )
            }
            className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
          />
        </div>
      </div>

      <div>
        <Label htmlFor="assignees">Assign To</Label>
        <div className="relative" ref={assigneeDropdownRef}>
          <div className="flex gap-2 mt-1">
            <div className="relative flex-1">
              <Input
                id="assignees"
                value={newAssignee}
                onChange={(e) => {
                  setNewAssignee(e.target.value);
                  setIsAssigneeDropdownOpen(true);
                }}
                onFocus={() => setIsAssigneeDropdownOpen(true)}
                placeholder="Start typing to filter users"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() =>
                  setIsAssigneeDropdownOpen(!isAssigneeDropdownOpen)
                }
              >
                <ChevronUpDownIcon className="h-4 w-4 text-gray-400" />
              </button>
            </div>
          </div>

          {isAssigneeDropdownOpen && (
            <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <div
                    key={user.userId}
                    className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                    onClick={() => handleAddAssignee(user.userId)}
                  >
                    <div className="flex items-center">
                      <span className="font-normal block truncate">
                        {user.email}
                      </span>
                    </div>
                    {assignedUsers.includes(user.userId) && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                        <CheckIcon className="h-5 w-5 text-green-500" />
                      </span>
                    )}
                  </div>
                ))
              ) : (
                <div className="py-2 px-3 text-gray-500">No users found</div>
              )}
            </div>
          )}
        </div>

        <div className="mt-2 flex flex-wrap gap-2">
          {assignedUsers.map((userId) => {
            const user = companyUsers.find((u) => u.userId === userId);
            return (
              <div
                key={userId}
                className="flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm"
              >
                <span className="mr-1">{user?.email || userId}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveAssignee(userId)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-end gap-2 pt-2">
        {onCancel && (
          <Button
            type="button"
            variant="outline"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        )}
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Saving..." : initialTask?.id ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
}
