import React from "react";

interface FilePasteAreaProps {
  onFilePaste: (file: File, previewUrl: string) => void;
  acceptedTypes?: string[]; // Array of MIME types to accept (e.g. ["image/jpeg", "image/png"])
  className?: string;
  placeholderText?: string;
}

export function FilePasteArea({
  onFilePaste,
  acceptedTypes = [],
  className = "",
  placeholderText = "Click here and paste a file (Ctrl+V)",
}: FilePasteAreaProps) {
  const handlePaste = async (e: React.ClipboardEvent) => {
    e.preventDefault();
    const items = e.clipboardData.items;

    for (const item of items) {
      // If acceptedTypes is empty, accept any file type
      // Otherwise, check if the item type is in the accepted types list
      if (
        acceptedTypes.length === 0 ||
        acceptedTypes.some(type => {
          // Handle wildcards like "image/*"
          if (type.endsWith('/*')) {
            const category = type.split('/')[0];
            return item.type.startsWith(`${category}/`);
          }
          return item.type === type;
        })
      ) {
        const file = item.getAsFile();
        if (file) {
          const previewUrl = URL.createObjectURL(file);
          onFilePaste(file, previewUrl);
          break; // Only handle the first matching file if multiple are pasted
        }
      }
    }
  };

  return (
    <div
      onPaste={handlePaste}
      tabIndex={0}
      className={`p-4 border-2 border-dashed border-gray-300 rounded-lg text-center cursor-pointer focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 ${className}`}
    >
      <p className="text-sm text-gray-600">
        {placeholderText}
      </p>
    </div>
  );
}

// For images
export function ImagePasteArea({
  onImagePaste,
  className = "",
}: {
  onImagePaste: (file: File, previewUrl: string) => void;
  className?: string;
}) {
  return (
    <FilePasteArea
      onFilePaste={onImagePaste}
      acceptedTypes={["image/*"]}
      className={className}
      placeholderText="Click here and paste an image (Ctrl+V)"
    />
  );
}
