"use client";

import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cn } from "@/app/lib/utils";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md border bg-white dark:bg-gray-800 text-black dark:text-white px-3 py-1.5 text-sm shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

// Simple directive-like tooltip component
interface WithTooltipProps {
  tooltip?: string;
  tooltipSide?: "top" | "right" | "bottom" | "left";
  tooltipAlign?: "start" | "center" | "end";
  tooltipOffset?: number;
  tooltipDelay?: number;
  tooltipClassName?: string;
  children: React.ReactElement;
}

/**
 * A simple directive-like tooltip component that can be used like:
 *
 * ```jsx
 * <WithTooltip tooltip="This is a tooltip">
 *   <button>Hover me</button>
 * </WithTooltip>
 * ```
 */
const WithTooltip = ({
  tooltip,
  tooltipSide = "top",
  tooltipAlign = "center",
  tooltipOffset = 4,
  tooltipDelay = 0,
  tooltipClassName,
  children,
}: WithTooltipProps) => {
  if (!tooltip) return children;

  return (
    <Tooltip delayDuration={tooltipDelay}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        side={tooltipSide}
        align={tooltipAlign}
        sideOffset={tooltipOffset}
        className={tooltipClassName}
      >
        {tooltip}
      </TooltipContent>
    </Tooltip>
  );
};

/**
 * A custom hook that returns props to add a tooltip to any element
 *
 * ```jsx
 * const tooltipProps = useTooltip("This is a tooltip");
 * return <button {...tooltipProps}>Hover me</button>;
 * ```
 */
const useTooltip = (
  content: string,
  options?: {
    side?: "top" | "right" | "bottom" | "left";
    align?: "start" | "center" | "end";
    sideOffset?: number;
    delayDuration?: number;
    className?: string;
  }
) => {
  const [open, setOpen] = React.useState(false);

  if (!content) return {};

  return {
    "data-tooltip": content,
    "data-tooltip-side": options?.side || "top",
    "data-tooltip-align": options?.align || "center",
    "data-tooltip-offset": options?.sideOffset || 4,
    "data-tooltip-delay": options?.delayDuration || 0,
    "data-tooltip-class": options?.className,
    onMouseEnter: () => setOpen(true),
    onMouseLeave: () => setOpen(false),
    onFocus: () => setOpen(true),
    onBlur: () => setOpen(false),
    "aria-label": content,
  };
};

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  WithTooltip,
  useTooltip,
};
